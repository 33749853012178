import React from 'react'
import logo from '../images/EzraFlynnIcon_Favicon2.png';
import NewRelease from '../components/NewRelease';
import Events from '../components/Events';
import placeholder from '../images/EzraFlynnIcon.png';
import Blog from '../components/Blog';
import TheAuthor from '../components/TheAuthor';
import Subscribe from '../components/Subscribe';
import { Container } from 'reactstrap';
import SectionHeader from '../components/SectionHeader';

const event_text = "We will be at the Batimore comic con to promote the book release and get the word out about Ezra. Additionally, we enjoy a bit of comics ourselves, so we will take some time to enjoy the event on our own time too. Read more"

const ezras100_text = `For each novel, Ezra makes 100 handmade copies. These are considered the first 100 copies called Ezra's 100. There are 75 softback binding, and 25 harback binding books. These are limited edition, and no more will ever be made. Only 100 per novel. Ezra uses the traditional method of sewn binding quality materials that are to last many years or longer if taken care of. The soft covers are technically a lower level hardcover, and the hardcovers are larger and thicker backboards.`

const theAuthor_text = "Watch the full series of how I arrived at the idea for SID and how ultimately I wrote this novel from zero to published. In it I go over writing, reading, how to get ideas, cover design, marketing, advertising, building your website, where to publish, copyright application, time management strategies, editing, the assembly line, and so much more. I have experienced this process first hand and documented the process along the way. Read more"


function Home() {
  return (
    <Container className='my-5'>
      <div>
        <div className='landing__section'>
          <div className='landing__center'>
            <img src={logo} className="App-logo" alt="logo" />
            <h1 className='title text-center text-black mt-4'>
              Coming Soon
            </h1>
          </div>
        </div>
      </div>
      {/* <SectionHeader header="New Release" />
      <NewRelease /> */}

      {/* <SectionHeader header="Blog" /> */}
      {/* <Blog title="Build A Free Website" text={blog_text} image={placeholder} video="" order=""/> */}

      {/* <SectionHeader header="Events" /> */}
      {/* <Events title="Baltimore Comic Con" text={event_text} image={placeholder} video="" order=""/> */}

      {/* <SectionHeader header="Ezra's 100" />
      <Blog title="Handmade by the author" text={ezras100_text} image={placeholder} video="" order=""/>

      <SectionHeader header="The Author" />
      <TheAuthor title="The Making of SID (Series)" text={theAuthor_text} image={placeholder} video="" order=""/> */}

      {/* <SectionHeader header="Subscribe" /> */}
      {/* <Subscribe /> */}

    </Container>
  )
}

export default Home