import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Ezras100 from './pages/Blog';
import Events from './pages/Events';
import MyStory from './pages/MyStory';
import Socials from './pages/Socials';
import Contact from './pages/Contact';

import './App.css';
import Footer from './components/Footer';
import Works from './pages/Works';
import ErrorPage from './pages/ErrorPage';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/works/" element={<Works />} />
          <Route path="/ezras-100/" element={<Ezras100 />} />
          <Route path="/events/" element={<Events />} />
          <Route path="/my-story/" element={<MyStory />} />
          <Route path="/socials/" element={<Socials />} />
          <Route path="/contact/" element={<Contact />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
