import React from 'react'
import logo from '../images/EzraFlynnLogo.png';

function Socials() {
  return (
    <div>
      <div className='landing__section'>
        <div className='landing__center'>
          <img src={logo} className="App-logo" alt="logo" />
          <h1 className='title text-center mt-4'>
            Coming Soon
          </h1>
        </div>
      </div>
    </div>
  )
}

export default Socials