import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, Collapse, NavbarText, Button } from 'reactstrap';

import logo from '../images/EzraFlynnIcon_Favicon2.png';
import logo2 from '../images/Ezra_logo_horizontal.png';
import Socials from './Socials';

function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [showNav, setShowNav] = useState(true);

    return (
        <nav className="fixed-top header">
            <Navbar isOpen={isOpen} expand="xl" full dark className='container gx-md-5 px-md-5 p-0'>
                <NavbarBrand href="/" className='me-md-5 pe-md-5'>
                    <img src={logo2} className="App-icon" alt="Ezra Flynn logo" />
                    {/* <span>Ezra Flynn</span> */}
                    <div className="divider d-none d-lg-block"></div>
                </NavbarBrand>
                <NavbarToggler onClick={() => setIsOpen(isOpen ? false : "isOpen")} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar className="justify-content-between mt-2" style={{ width: "100%" }}>
                        <NavLink onClick={() => setIsOpen(false)} to="/works">Works</NavLink>
                        <NavLink onClick={() => setIsOpen(false)} to="/ezras-100">Ezra's 100</NavLink>
                        {/* <NavLink onClick={() => setIsOpen(false)} to="/events">Events</NavLink> */}
                        <NavLink onClick={() => setIsOpen(false)} to="/my-story">The Author</NavLink>
                        {/* <NavLink onClick={() => setIsOpen(false)} to="/socials">Socials</NavLink>
                        <NavLink onClick={() => setIsOpen(false)} to="/contact">Contact</NavLink> */}
                        <Socials />
                    </Nav>
                </Collapse>
            </Navbar>
        </nav>
    )
}

export default Header