import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import DataSlab from '../components/DataSlab';
import FilterTable from '../components/FilterTable';
import SectionHeader from '../components/SectionHeader';
import logo from '../images/EzraFlynnLogo.png';

// Database data setup
const data = [
  {
    book_cover: "https://firebasestorage.googleapis.com/v0/b/ezraflynn.appspot.com/o/New%20Project%20(20).png?alt=media&token=1141b56d-e313-46bd-b22e-1e748270a339",
    book_blurb: "In this futuristic world of drones (robots) E-1s, D-1s, S-8s and other drones are designed and built by Slingshot Inc. Their partners Cingulariti, buy the drones and put them into use. Sid is an ordinary guy, broke, out of work, and trying to survive. Sid catches a break when he is hired to build a new prototype for Slingshot called the I-1, or intelligence class 1 which will be the first of its kind, an all new sentient being which",
    title: "SID: A Novel",
    release_date: "September 1, 2024",
    reviews: ["USA Today -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "CNN -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "Huffington Post -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''"],
    price_audiobook: "19.99",
    price_paperback: "15.99",
    price_hardback: "24.99",
    price_eBook: "9.99",
    featured: true,
    inspiration: "dadada put it on the work page",
    related_works: ["SID II", "SID III"],
    goodreads_giveaway: "https://www.goodreads.com/giveaway/enter_kindle_giveaway/377582-mo-ther-na-ture",
    genre: "Science-Fiction, Fantasy, Tragedy, Super Hero",
    id: "834h0r873h40",
    audible_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    kindle_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    amazon_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    kobo_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    barnes_nobles_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    spotify_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    apple_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    google_play_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  },
  {
    book_cover: "https://firebasestorage.googleapis.com/v0/b/ezraflynn.appspot.com/o/Dream_girl_cover_new_small.png?alt=media&token=6f8b1db5-0406-45e9-bc14-18fb8402cfda",
    book_blurb: "Have you ever woken up from a dream and wished you could go back to sleep in that dream for a while longer. Everyone’s had a dream about someone they like, or maybe it’s someone you’ve never met, but had a strong connection with. If you felt like that dream ended early or you wanted to go back to sleep to see if you could get back to that dream for just a little longer, then you may like this book. Because that’s exactly what happened to John. He had a dream about a girl he had never met before but she was perfect. When he woke up, he found himself missing her because he had developed feelings for her. As a scientist who builds large hadron colliders and successfully built the first arc 9 fusion reactors, he decides to build a device that will allow him to either live in his dream forever so he can be with her, or bring his dream girl over to the real world. Which will it be? Will he be successful? Will he get the girl? Or will his plan fail miserably? ",
    title: "Dream Girl: A Novel",
    release_date: "November 1, 2024",
    reviews: ["USA Today -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "CNN -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "Huffington Post -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''"],
    price_audiobook: "19.99",
    price_paperback: "15.99",
    price_hardback: "24.99",
    price_eBook: "9.99",
    featured: true,
    inspiration: "dadada put it on the work page",
    related_works: [],
    goodreads_giveaway: "https://www.goodreads.com/giveaway/enter_kindle_giveaway/377582-mo-ther-na-ture",
    genre: "Science-Fiction, Fantasy, Romance",
    id: "834h0r873h40",
    audible_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    kindle_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    amazon_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    kobo_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    barnes_nobles_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    spotify_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    apple_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    google_play_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  },
  {
    book_cover: "https://firebasestorage.googleapis.com/v0/b/ezraflynn.appspot.com/o/TheSixRealmsofReality.png?alt=media&token=c808f056-b04b-4cb0-a189-91c90c0e3bc7",
    book_blurb: "Have you ever woken up from a dream and wished you could go back to sleep in that dream for a while longer. Everyone’s had a dream about someone they like, or maybe it’s someone you’ve never met, but had a strong connection with. If you felt like that dream ended early or you wanted to go back to sleep to see if you could get back to that dream for just a little longer, then you may like this book. Because that’s exactly what happened to John. He had a dream about a girl he had never met before but she was perfect. When he woke up, he found himself missing her because he had developed feelings for her. As a scientist who builds large hadron colliders and successfully built the first arc 9 fusion reactors, he decides to build a device that will allow him to either live in his dream forever so he can be with her, or bring his dream girl over to the real world. Which will it be? Will he be successful? Will he get the girl? Or will his plan fail miserably? ",
    title: "The Six Realms of Reality: A Novel",
    release_date: "November 1, 2024",
    reviews: ["USA Today -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "CNN -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "Huffington Post -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''"],
    price_audiobook: "19.99",
    price_paperback: "15.99",
    price_hardback: "24.99",
    price_eBook: "9.99",
    featured: true,
    inspiration: "dadada put it on the work page",
    related_works: [],
    goodreads_giveaway: "https://www.goodreads.com/giveaway/enter_kindle_giveaway/377582-mo-ther-na-ture",
    genre: "Science-Fiction, Fantasy, Afterlife",
    id: "834h0r873h40",
    audible_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    kindle_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    amazon_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    kobo_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    barnes_nobles_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    spotify_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    apple_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
    google_play_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  },
  // {
  //   book_cover: "https://firebasestorage.googleapis.com/v0/b/ezraflynn.appspot.com/o/The_Fiction_Department.png?alt=media&token=cfe22476-311c-4d60-aad8-d3c0ce95ecbd",
  //   book_blurb: "Have you ever woken up from a dream and wished you could go back to sleep in that dream for a while longer. Everyone’s had a dream about someone they like, or maybe it’s someone you’ve never met, but had a strong connection with. If you felt like that dream ended early or you wanted to go back to sleep to see if you could get back to that dream for just a little longer, then you may like this book. Because that’s exactly what happened to John. He had a dream about a girl he had never met before but she was perfect. When he woke up, he found himself missing her because he had developed feelings for her. As a scientist who builds large hadron colliders and successfully built the first arc 9 fusion reactors, he decides to build a device that will allow him to either live in his dream forever so he can be with her, or bring his dream girl over to the real world. Which will it be? Will he be successful? Will he get the girl? Or will his plan fail miserably? ",
  //   title: "The Fiction Department: A Novel",
  //   release_date: "March 1, 2025",
  //   reviews: ["USA Today -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "CNN -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "Huffington Post -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''"],
  //   price_audiobook: "19.99",
  //   price_paperback: "15.99",
  //   price_hardback: "24.99",
  //   price_eBook: "9.99",
  //   featured: true,
  //   inspiration: "dadada put it on the work page",
  //   related_works: [],
  //   goodreads_giveaway: "https://www.goodreads.com/giveaway/enter_kindle_giveaway/377582-mo-ther-na-ture",
  //   genre: "Science-Fiction, Fantasy, Romance",
  //   id: "834h0r873h40",
  //   audible_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  //   kindle_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  //   amazon_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  //   kobo_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  //   barnes_nobles_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  //   spotify_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  //   apple_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  //   google_play_product: "https://www.amazon.com/Snow-Crash-Novel-Neal-Stephenson-ebook/dp/B000FBJCJE/?_encoding=UTF8&pd_rd_w=Ma6H6&content-id=amzn1.sym.add701ed-2d82-4af9-b095-43fa71d1fb4e%3Aamzn1.symc.0be80b80-fece-46bc-88bd-c8e6394f049e&pf_rd_p=add701ed-2d82-4af9-b095-43fa71d1fb4e&pf_rd_r=59BWXRNHN55THZQSZ8YE&pd_rd_wg=1KUl2&pd_rd_r=223338ff-ce6c-404f-900f-ccbd6b00bc79&ref_=pd_gw_ci_mcx_mr_hp_atf_m",
  // },
];

function Works() {

  return (
    <Container className='works my-5'>
      <SectionHeader header="Works" />
      {/* <Row className='justify-content-center'>
        <Col lg={9}>
          {data?.slice(0).reverse().map((work) => {
            return <DataSlab data={work} id={work.id} title={work.title} description={work.book_blurb} image={work.book_cover} date={work.release_date} type="works" />
          })}
        </Col>
      </Row> */}


      <div className='landing__section'>
        <div className='landing__center'>
          <img src={logo} className="App-logo" alt="logo" />
          <h1 className='title text-center text-black mt-4'>
            Coming Soon
          </h1>
        </div>
      </div>
      {/* <FilterTable data={data} tableTitle="Works" /> */}
    </Container>
  )
}

export default Works