import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faDiscord, faTwitter, faReddit, faFacebook, faInstagram, faTiktok, faTelegram, faMedium, faSquareXTwitter, faGoodreads } from "@fortawesome/free-brands-svg-icons";

function Socials() {
    return (
        <div className='mt-2 mt-lg-0'>
            <a href="https://www.instagram.com/ezraflynnbooks/" aria-label="Instagram" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} className="instagram fa fa-fw fa-medium" /></a>
            <a href="https://www.facebook.com/ezraflynnbooks/" aria-label="Facebook" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} className="facebook fa fa-fw fa-medium" /></a>
            <a href="https://www.goodreads.com/user/show/17386089-ezra-flynn" aria-label="GoodReads" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGoodreads} className="goodreads fa fa-fw fa-medium" /></a>
            <a href="https://x.com/ezraflynnbooks" aria-label="Twitter" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faSquareXTwitter} className="x fa fa-fw fa-medium" /></a>
            <a href="https://www.youtube.com/@EzraFlynnBooks" aria-label="Youtube" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faYoutube} className="youtube fa fa-fw fa-medium" /></a>
            <a href="https://www.tiktok.com/@ezraflynnbooks" aria-label="TikTok" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTiktok} className="tiktok fa fa-fw fa-medium" /></a>
            <a href="https://t.me/ezraflynnbooks" aria-label="TikTok" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTelegram} className="telegram fa fa-fw fa-medium" /></a>
        </div>
    )
}

export default Socials