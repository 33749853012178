import React from 'react'
import { Link } from 'react-router-dom'

function ErrorPage() {
  return (
    <div className='promo errorPage'>
      <h1 className='text-danger mb-5'>Ohh No! That page does not exists</h1>
      <Link to="/" className='whitepaper-button text-center w-auto px-3 pt-1'>Go to hompage</Link>
    </div>
  )
}

export default ErrorPage