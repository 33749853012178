import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube, faDiscord, faTwitter, faReddit, faFacebook, faInstagram, faTiktok, faTelegram, faMedium, faSquareXTwitter, faGoodreads } from "@fortawesome/free-brands-svg-icons";
import Socials from './Socials';

function Footer() {
    return (
        <footer>
            <Container className='pt-5 text-center'>
                <Row className='pt-2'>
                    <Col>
                        <h5 className='text-white fs-5'>Ezra Flynn</h5>
                        <a className='footer__links' href='/'>Home</a><br />
                        <a className='footer__links' href=''>Works</a><br />
                        <a className='footer__links' href=''>Ezra's 100</a><br />
                        {/* <a className='footer__links' href=''>Events</a><br /> */}
                        <a className='footer__links' href=''>The Author</a><br />
                        {/* <a className='footer__links' href=''>Socials</a><br /> */}
                        {/* <a className='footer__links' href=''>Contact</a> */}
                    </Col>
                    <Col>
                        <h5 className='text-white fs-5'>Platforms</h5>
                        <a className='footer__links' href=''>Amazon</a><br />
                        <a className='footer__links' href=''>Apple Books</a><br />
                        <a className='footer__links' href=''>Barnes & Nobles</a><br />
                        <a className='footer__links' href=''>Google Play</a><br />
                        <a className='footer__links' href=''>Kobo</a><br />
                        {/* <a className='footer__links' href=''>Audible</a><br /> */}
                        {/* <a className='footer__links' href=''>Spotify</a> */}
                    </Col>
                    {/* <Col>
                        <h5 className='text-white fs-5'>Work with Ezra</h5>
                        <a className='footer__links' href=''>Publishers</a>
                        <a className='footer__links' href=''>Film Producers</a>
                        <a className='footer__links' href=''>Educators</a>
                        <a className='footer__links' href=''>Librarians</a>
                        <a className='footer__links' href=''>Bookstores</a>
                        <a className='footer__links' href=''>Wholesellers</a>
                        <a className='footer__links' href=''>Conventions</a>
                    </Col> */}
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <p className='text-center'>Join the community</p>
                    </Col>
                </Row>
                <Row>
                    <Col className='ms-4'>
                        <Socials />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Ezra Flynn Copyright 2023. All rights reserved</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}


export default Footer;