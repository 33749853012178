import React from 'react'
import logo from '../images/EzraFlynnLogo.png';
import SectionHeader from '../components/SectionHeader';

function MyStory() {
  return (
    <div className='works my-5'>
      <SectionHeader header="The Author" />

      <div className='landing__section'>
        <div className='landing__center'>
          <img src={logo} className="App-logo" alt="logo" />
          <h1 className='title text-center text-black mt-4'>
            Coming Soon
          </h1>
        </div>
      </div>
    </div>
  )
}

export default MyStory