import React from 'react'
import { Col, Row } from 'reactstrap';

function SectionHeader({ header }) {
    return (
        <Row className='sectionHeader pt-4'>
            <Col>
                <h1 className='promo__headerText text-center text-black'>{header}</h1>
            </Col>
        </Row>
    )
}

export default SectionHeader