import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import DataSlab from '../components/DataSlab';
import SectionHeader from '../components/SectionHeader';
import logo from '../images/EzraFlynnLogo.png';
// Database data setup
const data = [
  {
    book_cover: "https://firebasestorage.googleapis.com/v0/b/ezraflynn.appspot.com/o/SID_cover_New_small.png?alt=media&token=aace4f40-66a6-4e71-a0af-f292a55a249b",
    book_blurb: "In this futuristic world of drones (robots) E-1s, D-1s, S-8s and other drones are designed and built by Slingshot Inc. Their partners Cingulariti, buy the drones and put them into use. Sid is an ordinary guy, broke, out of work, and trying to survive. Sid catches a break when he is hired to build a new prototype for Slingshot called the I-1, or intelligence class 1 which will be the first of its kind, an all new sentient being which",
    title: "SID",
    release_date: "September 1, 2024",
    reviews: ["USA Today -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "CNN -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "Huffington Post -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''"],
    price_audiobook: "19.99",
    price_paperback: "15.99",
    price_hardback: "24.99",
    price_eBook: "9.99",
    featured: true,
    inspiration: "dadada put it on the work page",
    related_works: ["SID II", "SID III"],
    goodreads_giveaway: "https://www.goodreads.com/giveaway/enter_kindle_giveaway/377582-mo-ther-na-ture",
    genre: "Science-Fiction, Fantasy, Tragedy, Super Hero",
    id: "834h0r873h40",
  },
  {
    book_cover: "https://firebasestorage.googleapis.com/v0/b/ezraflynn.appspot.com/o/SID_cover_New_small.png?alt=media&token=aace4f40-66a6-4e71-a0af-f292a55a249b",
    book_blurb: "In this futuristic world of drones (robots) E-1s, D-1s, S-8s and other drones are designed and built by Slingshot Inc. Their partners Cingulariti, buy the drones and put them into use. Sid is an ordinary guy, broke, out of work, and trying to survive. Sid catches a break when he is hired to build a new prototype for Slingshot called the I-1, or intelligence class 1 which will be the first of its kind, an all new sentient being which",
    title: "SID",
    release_date: "September 1, 2024",
    reviews: ["USA Today -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "CNN -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''", "Huffington Post -  ''An addictive read... part marvel comic hero origination story, part bicentennial man.''"],
    price_audiobook: "19.99",
    price_paperback: "15.99",
    price_hardback: "24.99",
    price_eBook: "9.99",
    featured: true,
    inspiration: "dadada put it on the work page",
    related_works: ["SID II", "SID III"],
    goodreads_giveaway: "https://www.goodreads.com/giveaway/enter_kindle_giveaway/377582-mo-ther-na-ture",
    genre: "Science-Fiction, Fantasy, Tragedy, Super Hero",
    id: "834h0r873h40",
  },
];

function Events() {
  return (
    <Container className='my-5'>
      <SectionHeader header="Events" />
      <Row className='justify-content-center'>
        <Col lg={9}>
          {data?.map((event) => {
            return <DataSlab data={event} id={event.id} title={event.title} description={event.book_blurb} image={event.book_cover} date={event.release_date} type="events" />
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default Events