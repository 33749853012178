import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import audible_icon from '../images/audible_icon.png';
import kindle_icon from '../images/kindle_icon.png';
import amazon_icon from '../images/amazon_icon.png';
import apple_books_icon from '../images/apple_books_icon.png';
import barnes_nobles_icon from '../images/barnes_nobles_icon.png';
import google_play_icon from '../images/google_play_icon.png';
import kobo_icon from '../images/kobo_icon.png';
import spotify_icon from '../images/spotify_icon.png';

function BuyNowModal({ data }) {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [email, setEmail] = useState("");

    return (
        <>
            <Button outline className='whitepaper-button py-0 mb-2 w-auto fs-6 me-3'
                onClick={() => setIsLoginModalOpen(isLoginModalOpen ? false : true)}
            >Buy Now</Button>
            <Modal
                className='subcribeModal'
                size="md"
                isOpen={isLoginModalOpen}
                toggle={() => setIsLoginModalOpen(isLoginModalOpen ? false : true)}
            >
                <ModalHeader toggle={() => setIsLoginModalOpen(false)}>
                    <h3 className='p-0 m-0 subscribeModal__header'><strong>Choose a platform</strong></h3>
                </ModalHeader>
                <ModalBody className='subscribeModal__body d-flex flex-column pt-4 rounded-bottom '>
                    <Row>
                        <Col className='text-center'>
                            <img src={data.book_cover} className='dataSlab__imageMobile m-auto align-self-center' alt='' /><br />

                            <h5 className='fs-2 mt-3'>ORDER TODAY</h5>
                            <p className='text-black mb-2'>List Price | Price may vary by retailer</p>
                            <p className='text-black m-0'>Audiobook: ${data.price_audiobook}</p>
                            <p className='text-black m-0'>Paperback: ${data.price_paperback}</p>
                            <p className='text-black m-0'>Hardback: ${data.price_hardback}</p>
                            <p className='text-black m-0'>eBook: ${data.price_eBook}</p>


                            <div className='my-3 text-center'>
                                <a href={data.audible_product} aria-label="Audible" target="_blank" rel="noreferrer"><img src={audible_icon} className='newRelease__plateformIcon' alt='' /></a>
                                <a href={data.kindle_product} aria-label="Kindle" target="_blank" rel="noreferrer"><img src={kindle_icon} className='newRelease__plateformIcon' alt='' /></a>
                                <a href={data.amazon_product} aria-label="Amazon" target="_blank" rel="noreferrer"><img src={amazon_icon} className='newRelease__plateformIcon' alt='' /></a>
                                <a href={data.kobo_product} aria-label="Kobo" target="_blank" rel="noreferrer"><img src={kobo_icon} className='newRelease__plateformIcon' alt='' /></a>
                                <a href={data.barnes_nobles_product} aria-label="Barnes & Nobles" target="_blank" rel="noreferrer"><img src={barnes_nobles_icon} className='newRelease__plateformIcon' alt='' /></a>
                                <a href={data.spotify_product} aria-label="Spotify" target="_blank" rel="noreferrer"><img src={spotify_icon} className='newRelease__plateformIcon' alt='' /></a>
                                <a href={data.apple_product} aria-label="Apple Books" target="_blank" rel="noreferrer"><img src={apple_books_icon} className='newRelease__plateformIcon' alt='' /></a>
                                <a href={data.google_play_product} aria-label="Google Play" target="_blank" rel="noreferrer"><img src={google_play_icon} className='newRelease__plateformIcon' alt='' /></a>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default BuyNowModal