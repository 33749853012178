import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import BuyNowModal from './BuyNowModal'


function DataSlab({ data, title, description, image, date, type, id }) {

    return (
        <>
            <div href="/" className='promo d-flex mb-3 shadow'>
                <img src={image} className='dataSlab__imageDesktop m-0 m-lg-3 align-self-center d-none d-lg-block' alt='' /><br />
                <div className='w-100 mx-3 mt-1 mt-lg-3 text-center text-lg-start'>
                    <img src={image} className='dataSlab__imageMobile m-auto m-lg-3 align-self-center d-block d-lg-none' alt='' /><br />
                    <div className='w-100'>
                        <h5 className='fs-5 fw-bold'>{title}</h5>
                        <p className='text-black'>Published: {date}</p>
                        <p className='text-black d-lg-block'>{description.slice(0, 200) + "..."}</p>
                        <div className='d-flex justify-content-center justify-content-lg-start'>
                            {type === "blog" || type === "events" ? "" : <><BuyNowModal data={data} /><br /></>}
                            <Link className='me-0' to={`/works/${id}`}><Button className='whitepaper-button py-0 mb-2 w-auto fs-6'>Learn more</Button></Link>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DataSlab